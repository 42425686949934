<template>
  <v-row dense>
    <v-col cols="12" sm="6">
      <v-img v-if="profile.sex=='男性'" src="img/dummy_men.png"></v-img>
      <v-img v-if="profile.sex=='女性'" src="img/dummy_women.png"></v-img>
    </v-col> 
    <v-col cols="12" sm="6" >
      <p class="text-decoration-underline">{{profile.nickname}}さんの自己紹介</p>
      <p class="profile_text bg-grey-lighten-4 pa-2" v-html="profile.text.replace(/<|>/g,'').replace(/\n/g, '<br>')"></p>
      <p class="mt-3 text-decoration-underline">{{profile.nickname}}さんの基本情報</p>
      <v-row>
        <v-col cols="5">ニックネーム</v-col>
        <v-col cols="7">{{profile.nickname}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">性別</v-col>
        <v-col cols="7">{{profile.sex}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">年齢</v-col>
        <v-col cols="7">{{profile.age}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">居住地</v-col>
        <v-col cols="7">{{profile.pref}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">職業</v-col>
        <v-col cols="7">{{profile.job}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">年収</v-col>
        <v-col cols="7">{{profile.income}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">最終学歴</v-col>
        <v-col cols="7">{{profile.education}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="5">趣味</v-col>
        <v-col cols="7"><span v-for="item in profile.hobby" :key="item">{{ item + " " }} </span></v-col>
      </v-row>
      <p class="mt-3 text-decoration-underline">価値観タイプ</p>
      <p class="font-weight-black text-pink">{{ type.type_name }}</p>
      <p class="profile_text bg-grey-lighten-4 pa-2" v-html="type.description"></p>
      <v-btn 
        class="bg-pink text-white mt-1"
        block
        elevation="2"
        v-on:click="runIdentify()"
      >
        <v-icon>mdi-pencil-box-multiple-outline </v-icon>価値観診断を行う
      </v-btn>
      <v-dialog
        v-model="identify_dialog"
        persistent
      >
        <v-card>
          <v-toolbar
            color="pink"
            dark
            class="text-white px-2"
          >プバシィ価値観診断</v-toolbar>
          <v-card-text>
            <div v-if="identify_q==0" class="text-h6 pa-12">これから、真のあなたを理解する、{{questions.length}}つの質問をします。</div>
            <div v-if="identify_q!=0 && identify_q<=questions.length" class="text-h6 pa-12">{{ questions[identify_q-1].text }}</div>
            <div v-if="identify_q>questions.length" class="text-h6 px-12 pt-12 pb-6">あなたは<span class="text-h6 font-weight-black text-pink">{{calcType().type_name}}</span>です。</div>
            <div v-if="identify_q>questions.length" class="text-body-1 px-12 pt-6 pb-6 mb-6 bg-grey-lighten-4"><span class="font-weight-black">{{calcType().type_name}}とは</span><br><span v-html="calcType().description" /></div>
          </v-card-text>
          <v-slider
            v-model="q_answer[identify_q-1]"
            v-show="identify_q!=0 && identify_q<=questions.length"
            :ticks="tickLabels"
            :max="2"
            step="1"
            show-ticks="always"
            tick-size="4"
            class="px-2"
          ></v-slider>
          <v-card-actions class="justify-end">
            <v-btn
              color="pink darken-1"
              text
              @click="identify_dialog = false"
            >
              やめる
            </v-btn>
            <v-btn
              color="pink darken-1"
              text
              @click="identify_q++"
              v-if="identify_q<=questions.length"
            >
              つづける
            </v-btn>
            <v-btn
              color="pink darken-1"
              text
              @click="setType()"
              v-if="identify_q>questions.length"
            >
              タイプに合う異性を探す
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        class="mt-1"
        block
        size="small"
        color="error"
        v-on:click="confirmReset()"
        variant="outlined"
      >
        プロフィールをリセットする
      </v-btn>
      <v-dialog
        v-model="reset_confirm_dialog"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-text class="text-h6">
            本当にプロフィールをリセットしますか？
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="pink darken-1"
              text
              @click="reset_confirm_dialog = false"
            >
              やめる
            </v-btn>
            <v-btn
              color="pink darken-1"
              text
              @click="doReset()"
            >
              リセットする
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col> 
  </v-row>
  <v-dialog
    v-model="first_profile_setting_dialog"
    persistent    
    fullscreen
  >
    <v-card v-show="!test_regist">
      <v-card-title class="ma-0 pa-0">
        <v-img
          class="bg-pink-lighten-4"
          width="100%"
          src="/img/enmusubi_lp.png"
        ></v-img>
      </v-card-title>
      <v-card-actions class="bg-purple-lighten-5">
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <p class="text-h4 text-pink-darken-1 font-weight-black ">
                今すぐお試し登録！
              </p>
              <p class="text-pink-darken-1 text-left mt-3">
              「プバシィ縁結び」は、独自の価値観診断を通じた「価値観マッチング™」が特徴です。他の婚活サイト・マッチングサイトとは桁違いの圧倒的なマッチング満足度を誇ります。お試し登録することで、実際にマッチング候補のリストをみることができます。百聞は一見にしかず。まずは、お試し登録をおすすめします。<br>
※お試し登録後に価値観診断を実施いただくことで、価値観マッチングの体験をすることができます。<br>
※メッセージ送信機能等の一部機能はは本登録後にご利用可能です。
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                size="x-large"
                variant="outlined"
                color="blue-darken-4"
                block
                class="mt-2 font-weight-black bg-blue-lighten-4"
                prepend-icon="mdi-pencil"
                @click="test_regist=true;profile.sex='男性'"
              >
                男性として<br>お試し登録
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                size="x-large"
                variant="outlined"
                color="pink-darken-4"
                block
                class="mt-2 font-weight-black bg-pink-lighten-4"
                prepend-icon="mdi-pencil"
                @click="test_regist=true;profile.sex='女性'"
              >
                女性として<br>お試し登録
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <v-card v-show="test_regist">
      <v-toolbar
        color="pink"
        dark
        class="text-white px-2"
      >プロフィール登録</v-toolbar>
      <v-card-text>
        <p>ようこそ、プバシィ縁結びへ。まずはあなたのことを教えてください。</p>
        <v-container>
          <v-form
            ref="form"
            v-model="profile_form_valid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="profile.nickname"
                  label="ニックネーム"
                  required
                  :rules="[v => !!v || 'ニックネームを入力してください']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="profile.sex"
                  :items="['男性', '女性']"
                  label="性別"
                  required
                  :rules="[v => !!v || 'Item is required']"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="profile.age"
                  label="年齢"
                  hint="数字で入力してください"
                  required
                  :rules="[v => !!v || '年齢を入力してください', v => /[0-9]+|[０-９]+/.test(v) || '年齢は数字で入力してください']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="profile.job"
                  :items="['会社員', '役員・経営者', '公務員', '自営業', '団体職員', '派遣社員', 'アルバイト', '家事手伝い', 'プロゲーマー','ユーチューバー','インフルエンサー','その他']"
                  label="職業"
                  required
                  :rules="[v => !!v || 'Item is required']"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="profile.pref"
                  :items="['北海道','東北地方','関東地方','中部地方','近畿地方','中国地方','四国地方','九州地方','沖縄', '中つ国', '第3村','ドリームランド']"
                  label="居住地"
                  required
                  :rules="[v => !!v || 'Item is required']"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="profile.income"
                  :items="['200万円以下', '200万円～400万円', '400万円～600万円', '600万円～800万円', '800万円～1000万円', '1000万円～2000万円', '2000万円以上']"
                  label="年収"
                  required
                  :rules="[v => !!v || 'Item is required']"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="profile.education"
                  :items="['義務教育', '高等学校・高等専門学校', '専門学校・短大', '大学', '大学院']"
                  label="最終学歴"
                  required
                  :rules="[v => !!v || 'Item is required']"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="profile.hobby"
                  :items="['スポーツ', 'アウトドア', '料理', 'アート','旅行','美容・健康','ボランティア','ペット','園芸','ハンドメイド','音楽','読書', '映画鑑賞', 'テレビ鑑賞', 'テレビゲーム', 'ユーロゲーム', 'マーダーミステリー', '脱出ゲーム']"
                  label="趣味"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="profile.text"
                  label="自己紹介"
                  auto-grow
                  filled
                ></v-textarea>
              </v-col>
            </v-row>
            <v-btn 
              class="bg-pink text-white mt-1"
              block
              elevation="2"
              v-on:click="setProfile()"
              :disabled="!profile_form_valid"
            >
              <v-icon>mdi-pencil</v-icon>プロフィールを登録する
            </v-btn>
          </v-form>
        </v-container>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<style scoped lang="css">
</style>

<script>

export default {
  name: 'MyselfSetting',
  emits: ["setFirstVisit", "setMyProfile"],
  props: ["is_first_visit"],

  methods: {
    runIdentify(){
      this.identify_dialog = true;
      this.identify_q = 0;
    },

    calcType(){
      let pvacy_point = 0;
      for(let i=0; i<this.questions.length; i++){
        pvacy_point = pvacy_point + (this.q_answer[i]-1)*this.questions[i].coe;
        //console.log((this.q_answer[i]-1)*this.questions[i].coe);
      }

      if(pvacy_point == this.questions.length){
        return this.types[1]; 
      }else if(pvacy_point >= -this.questions.length/2){
        return this.types[2]; 
      }else{
        return this.types[3];
      }
    },

    setType(){
      this.type = this.calcType()
      localStorage.q_answer = this.q_answer;
      console.log("write" + localStorage.q_answer)
      localStorage.type = this.type.type_id;
      this.identify_dialog = false;
      this.$emit("setMyProfile", this.profile, this.type);
    },

    confirmReset(){
      this.reset_confirm_dialog = true;
    },

    doReset(){
      localStorage.clear();
      location.reload();
    },

    setProfile(){
      this.first_profile_setting_dialog = false;
      localStorage.profile = JSON.stringify(this.profile);
      this.$emit("setFirstVisit", false);
      this.$emit("setMyProfile", this.profile, this.type);
    },
  },

  data: () => ({
    profile : {
      nickname: null,
      sex: null,
      age: null,
      pref: null,
      job: null,
      income: null,
      education: null,
      hobby: null,
      text: "プロフィール見ていただいてありがとうございます。\n日常で出会いがなく、登録してみました。\n\nお互いの価値観を尊重しあい、支えあえる関係になれたらいいですね。\n\n少しでも気になっていただけたら、ぜひ「いいね！」してください。\nよろしくお願いします。"
    },
    profile_form_valid: true,
    identify_dialog : false,
    identify_q : 0,
    questions : [
      {text: '結婚しても、自分だけの時間を持つことは大切だ', coe: 1},
      {text: '恋人ならば、相手のスマートフォンを勝手に見ても良い', coe: -1},
      {text: '付き合うのであれば、学歴や年収は正直に明かすべきだ', coe: -1},
      {text: '誰にも言えない秘密を持っていることは、おかしいことではない', coe: 1},
      {text: '死ぬ直前に、自分のパソコンやスマートフォンを破壊してほしい', coe: 1},
      {text: '「死人に口なし」は悪い意味の言葉だと思う', coe: -1},
    ],
    q_answer : [],
    tickLabels : {
      0: '思わない', 
      1: 'どちらでも', 
      2: '思う'},
    types : [
      {
        type_id: 0,
        type_name: "ノーマルタイプ",
        description: "まだ価値観診断を行っていません。<br>プバシィ縁結びの価値観診断は、性格や考え方をいくつかのタイプに分けることで、メールや数回のデートだけではわからない相性を見極めることができます。ぜひお試しください。"
      }, {
        type_id: 1,
        type_name: "プライバシーがいちばんタイプ",
        description: "あなたはプライバシーを大切にする価値観を持っています。<br>自分のプライバシーも、相手のプライバシーも大事。プライバシーを追及する気持ちは、他人を思いやる気持ちです。<br>きっと恋人のことも大切にすることでしょう。",
      }, {
        type_id: 2,
        type_name: "我が道を行くノンポリタイプ",
        description: "あなたは質問だけでは計り知れない価値観を持っています。<br>ひょっとすると、他の婚活サイトの方が向いているのかもしれません。退会をおすすめします。",
      }, {
        type_id: 3,
        type_name: "一般意思3.0タイプ",
        description: "人類補完計画のもとにすべての意思が統合され、一般意思だけが世界に残る。そんな未来を先んじて体現しているのがあなたです。<br>あなたは私。<br>あなたはセカイ。",
      }
    ],
    type : null,
    reset_confirm_dialog : false,
    first_profile_setting_dialog : false,
    test_regist: false,
  }),
  
  created() {
    if(localStorage.q_answer){
      console.log("read" + localStorage.q_answer)
      this.q_answer = localStorage.q_answer.split(',');
    }else{
      this.questions.map(() => this.q_answer.push(1));
    }

    if(localStorage.type){
      this.type = this.types[localStorage.type];
    }else{
      this.type = this.types[0];
    }
  },

  mounted() {
    if(localStorage.profile){
      try{
        this.profile = JSON.parse(localStorage.profile);
        this.$emit("setMyProfile", this.profile, this.type)
      }catch(e){ 
        this.$emit("setFirstVisit", true);
        console.log(e);
      }
      console.log(this.profile);
    }else if(this.is_first_visit){
      this.first_profile_setting_dialog = true;
    }
  },
}

</script>
