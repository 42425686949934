<template>
    <header>
      <v-app-bar flat elevate-on-scroll prop>
        <v-toolbar-title><h1><v-img src="/img/enmusubi_title.png" alt="プバシィ縁結び" max-height="40" /></h1></v-toolbar-title>
      </v-app-bar>
    </header>

    <v-container class="icon-menu grey lighten-5">
      <v-row no-gutters>
        <v-col
          v-for="(m, i) in icon_menu"
          :key="i"
          cols="3"
        >
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            flat
            v-on:click="$emit('clickHeaderButton',m.type)"
          >
            <v-icon color="pink" size=36px>{{ m.icon }}</v-icon>
            <br>
            <span class="icon-menu-text">{{ m.name }}</span>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

</template>

<style scoped lang="css">
h1 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 30px/60px FOT-MatisseEleganto Pro UB;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--standard-gray);
  text-align: center;
  opacity: 1;
}
.icon-menu-text{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-hiragino-kaku-gothic-pro);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--standard-gray);
  text-align: center;
  opacity: 1;
}
</style>

<script>

export default {
  name: 'MainHeader',
  emits: ['clickHeaderButton'],

  methods: {
  },

  data: () => ({
    icon_menu: [{
      name: '探す',
      icon: 'mdi-magnify',
      type: 'search',
    },{
      name: 'いいね',
      icon: 'mdi-cards-heart',
      type: 'like',
    },{
      name: 'チャット',
      icon: 'mdi-message-text',
      type: 'chat',
    },{
      name: '個人設定',
      icon: 'mdi-account',
      type: 'setting',
    }],
  }),
}

</script>
